.phone-input-container {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .country-code-select {
    width: 20%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #12529f;
    border-radius: 4px 0 0 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: inherit;
  }
  
  .country-code-select option {
    display: block;
    font-size: 16px;
    padding: 10px;
  }
  
  .phone-number-input {
    width: 80%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #12529f;
    border-radius: 0 4px 4px 0;
  }
  
  .country-code-select:focus + .phone-number-input {
    border-left: 0;
  }
  
  .phone-number-input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .country-code-select:focus {
    outline: none;
    border-color: #007bff;
  }
  