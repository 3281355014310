.image-slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%; /* Ensure the slider takes full parent dimensions */
    padding-bottom: 30px;
  }
  
  .slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .slide {
    min-width: 100%;
    height: 100%;
  }
  
  .slide img {
    width: 100%; 
    height: 100%;
    object-fit: contain;
    display: block;
  }
  
  .dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dot {
    width: 4.5%;
    height: 10px;
    margin: 0 .25%;
    background: rgb(63, 60, 60);
    border-radius: 3px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .dot.active {
    background: rgb(14, 98, 207);
  }
  