.audioGetter {
    width: 100%;
    display: flex;
    height: 40px;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 8%;
    background-color: #f2f2f2;
    border-radius: 30px;
    overflow: hidden;
}


/* input[type=range] {
    width: 65%;    
} */

.audioGetter>button {
    margin: 0;
    padding: 0;
    width: 10%;
    text-align: center;
    margin-top: 2px;
}

.audioGetter>button>.icons {
    font-size: 18px !important;
}
.audioGetter>span {
    width: 10%;
    font-size: 12px;
    text-align: center;
    display: block;
    margin-top: 12px;
}